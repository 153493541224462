<template>
  <b-container fluid>
    <b-row>
        <b-col lg="12" sm="12">
            <iq-card>
                <template v-slot:body>
                    <b-overlay :show="loading">
                        <b-row>
                        <b-col lg="12" sm="12">
                            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                            <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset">
                                <ValidationProvider name="Training Quarter Name (En)" vid="training_quarter_name" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="training_quarter_name"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.training_quarter_name_en')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="training_quarter_name"
                                    v-model="trainingQuarterData.training_quarter_name"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                                <ValidationProvider name="Training Quarter Name (Bn)" vid="training_quarter_name_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="training_quarter_name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.training_quarter_name_bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="training_quarter_name_bn"
                                    v-model="trainingQuarterData.training_quarter_name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                                <ValidationProvider name="Month From" vid="month_from" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="month_from"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.month_from')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    class="fromDate"
                                    v-model="trainingQuarterData.month_from"
                                    :placeholder="$t('globalTrans.select_date')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                                <ValidationProvider name="Month To" vid="month_to" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="month_to"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.month_to')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    class="fromDate"
                                    v-model="trainingQuarterData.month_to"
                                    :placeholder="$t('globalTrans.select_date')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                                <ValidationProvider name="Description (En)"  vid="description" rules="">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="5"
                                        label-for="description"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                        {{$t('elearning_config.description_en')}}
                                        </template>
                                        <b-form-textarea
                                            rows="2"
                                            id="description"
                                            v-model="trainingQuarterData.description"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-textarea>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                                <ValidationProvider name="Description (Bn)"  vid="description_bn" rules="">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="5"
                                        label-for="description_bn"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                        {{$t('elearning_config.description_bn')}}
                                        </template>
                                        <b-form-textarea
                                            rows="2"
                                            id="description_bn"
                                            v-model="trainingQuarterData.description_bn"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-textarea>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                                <div class="row">
                                <div class="col-sm-3"></div>
                                <div class="col text-right">
                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                    &nbsp;
                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                </div>
                                </div>
                            </b-form>
                            </ValidationObserver>
                        </b-col>
                        </b-row>
                    </b-overlay>
                </template>
            </iq-card>
        </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingQuarterSetupStore, trainingQuarterSetupUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.gettrainingQuarterData()
      this.trainingQuarterData = tmp
    }
  },
  mounted () {
    core.index()
        flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      trainingQuarterData: {
        id: '',
        training_quarter_name: '',
        training_quarter_name_bn: '',
        month_from: '',
        month_to: '',
        description: '',
        description_bn: '',
        status: 1
      }
    }
  },
  methods: {
    gettrainingQuarterData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.trainingQuarterData.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${trainingQuarterSetupUpdate}/${this.id}`, this.trainingQuarterData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, trainingQuarterSetupStore, this.trainingQuarterData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
